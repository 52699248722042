.selfhost-container {
  height: 100% - 40px;
}

.selfhost-heading {
  font-weight: 600;
  font-size: 2.6rem;
  padding: 2% 10%;
}
.selfhost-subtext {
  font-weight: 300;
  font-size: 16px;
  text-align: center;
}
.selfhost-guide {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: left;
  padding: 4%;
}
.selfhost-guide-left {
  width: 25%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 8px;
}
.selfhost-guide-heading {
  font-weight: 550;
  font-size: 2rem;
}
.points-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  padding: 1% 0;
}
.point {
  padding: 1% 2%;
}
